import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

const LanguageMap = {
  en: 'English',
  zh: '华语'
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "app";

  constructor(
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
    this.getLanguage()
  }

  getLanguage() {
    const lastSelectedLanguage = localStorage.getItem(`language`)
    if(!lastSelectedLanguage) {
      const browserLang = this.translateService.getBrowserLang()
      this.translateService.use(Object.keys(LanguageMap).indexOf(browserLang) > -1 ? browserLang : 'en')
      localStorage.setItem(`language`, this.translateService.currentLang)
    }
    else {
      this.translateService.use(lastSelectedLanguage)
    }
  }
}
