import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenService } from '../services/token.service';
 
 
@Injectable({
  providedIn: 'root'
})
export class AccessGuardService implements CanActivate {
 
  constructor(
    private tokenService: TokenService,
    private router: Router
  ) { }
 
  canActivate(route: ActivatedRouteSnapshot) {
    if(this.tokenService.getUserProfile()){
      return true
    }
    else {
      this.router.navigate(['/authentication', 'login'])
      return of (false)
    }
  }
 
}