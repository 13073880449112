import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, of } from 'rxjs'
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

const VALID_STATUS = [200, 201, 204]

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true
    })
    return next.handle(req).pipe(
      
      tap((event: HttpEvent<any>) => {
        if (event && event.type == 0) {
          return event
        }
        else if (VALID_STATUS.includes(event['status'])) {
          return event
        }
        throw event
      }),
      catchError((error: HttpErrorResponse) => {
        if (error['status'] === 401) {
          this.router.navigate(['/authentication', 'login'])
        }
        return throwError(error)
      })
    )
  }

  constructor(private router: Router) {  }
}