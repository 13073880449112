import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  accessToken = localStorage.getItem(`accessToken`)
  refreshToken = localStorage.getItem(`refreshToken`)
  userProfile = localStorage.getItem(`userProfile`)? JSON.parse(localStorage.getItem(`userProfile`)) : null
  
  constructor() { }

  setToken(accessToken, refreshToken?) {
    this.accessToken = accessToken
    localStorage.setItem(`accessToken`, this.accessToken)
    if(refreshToken) {
      this.refreshToken = refreshToken
      localStorage.setItem(`refreshToken`, this.refreshToken)
    }
  }

  setUserProfile(userProfile){
    this.userProfile = userProfile
    localStorage.setItem(`userProfile`, JSON.stringify(this.userProfile))
  }

  getAccessToken() {
    return this.accessToken
  }

  getRefreshToken() {
    return this.refreshToken
  }

  getUserProfile(){
    return this.userProfile
  }

  getUserGroupId() {
    return this.userProfile.groupId
  }

  getUserRoleId() {
    return this.userProfile.roleId
  }

  clearCache(){
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userProfile')
    delete this.accessToken
    delete this.refreshToken
    delete this.userProfile
  }
}
